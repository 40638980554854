<script setup lang="ts">
const props = defineProps<{
  phone?: string;
}>();

const { primaryColor } = usePrimaryColor();

const phone = computed(() => {
  return (useRoute().query?.phone as string) || props.phone || undefined;
});

const phoneStripped = computed(() => {
  if (!phone.value) return;
  return phone.value.replace(/\D/g, "");
});

// allow user to provide the desired format
// or a stripped number and we'll format for them
const phoneFormatted = computed(() => {
  if (!phone.value) return;
  if (phoneStripped.value === phone.value) {
    return phoneStripped.value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return phone.value;
});

const show = ref(false);
setTimeout(() => {
  show.value = true;
}, 1);
</script>
<template>
  <Transition name="phone">
    <a
      :href="`tel:${phoneStripped}`"
      class="flex items-center pr-10 mb-5 overflow-hidden border rounded-lg top-1 z-999 bg-base-100"
      v-if="show && phone"
    >
      <div
        class="flex items-center self-stretch justify-center p-4"
        :style="{ background: primaryColor as string }"
      >
        <IconPhone class="inline-block w-8 h-8 text-white" />
      </div>

      <div class="p-1 pl-5">
        <p>Speak to a recruiter today!</p>

        <p
          class="text-xl font-bold"
          :style="{ color: primaryColor as string }"
          v-if="phone"
        >
          {{ phoneFormatted }}
        </p>
      </div>
    </a>
  </Transition>
</template>

<style>
.phone-enter-active,
.phone-leave-active {
  transition: all 0.5s ease;
}

.phone-enter-from,
.phone-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
